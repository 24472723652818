import { useState, useEffect } from 'react'

const usePrevNextLinks = ({ data, slug, page, pageSize }) => {
  const [links, setLinks] = useState([])

  useEffect(() => {
    const nlinks = []

    if (page > 1) {
      nlinks.push(generateLinkObject('prev', slug, page - 1))
    }

    if (hasPage(data, page + 1, pageSize)) {
      nlinks.push(generateLinkObject('next', slug, page + 1))
    }

    setLinks(nlinks)
  }, [data, slug, page, pageSize])

  return links
}

function generateLinkObject(rel) {
  return {
    rel,
    href: '#'
  }
}

function hasPage(data, page, pageSize) {
  return data.length - (page - 1) * pageSize > 0
}

export const dataHasPage = hasPage

export default usePrevNextLinks
