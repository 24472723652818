import React from 'react'
import cn from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import getContentByLocale from '@u/get-content-by-locale'

const query = graphql`
  query {
    translations: allDatoCmsTranslation {
      edges {
        node {
          locale
          loadMore
        }
      }
    }
  }
`

function setScrollPosition() {
  let currentPosition = window.pageYOffset

  setTimeout(() => {
    let newPosition = window.pageYOffset
    window.scrollBy(0, currentPosition - newPosition)
  }, 80) // 80ms should be enough for react to insert and render new dom elements
}
export default function LoadMoreButton(props) {
  const { disabled, locale, href, onClick, ...rest } = props
  const {
    translations: { edges: translationEdges }
  } = useStaticQuery(query)
  const translation = getContentByLocale(
    locale,
    translationEdges,
    'node.locale'
  )?.node

  return (
    <a
      href={href}
      className={cn(
        'btn',
        'btn--round',
        'btn--red',
        disabled && 'btn--disabled'
      )}
      onClick={ev => {
        ev.preventDefault()
        onClick && onClick()
        setScrollPosition()
      }}
      {...rest}
    >
      {translation && translation.loadMore}
    </a>
  )
}
