import React from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'

import * as styles from './index.module.css'
import Icon from '@c/icon'

function AuthorResourceCard({
  children,
  url,
  title,
  type = 'Book',
  action,
  className
}) {
  return (
    <article className={cn(styles.card, className)}>
      <Link to={url} className={styles.link}>
        <header className={styles.header}>
          <span>{type}</span>
          <span className={styles.type}>
            {action} <Icon className="ml-8" />
          </span>
        </header>
        <div>
          {title && <h4>{title}</h4>}
          {children && <p className={styles.child}>{children}</p>}
        </div>
      </Link>
    </article>
  )
}

export default AuthorResourceCard
