import { useState, useCallback } from 'react'
import chunk from 'lodash/chunk'
import usePrevNextLinks from '@h/use-prev-next-links'
import Content from '../models/Content'
import ContentFactory from '../models/ContentFactory'

export default function usePaginatedContentList({
  contents,
  search,
  contentsPerPage,
  contentsFirstPage = null,
  slug
}) {
  const firstPageSize = contentsFirstPage || contentsPerPage
  const defaultPage = search.page ? Number(search.page) : 1
  const [state, setState] = useState(() => {
    const data = contents.map(data => ContentFactory.create(data))
    const pages = [data.slice(0, firstPageSize)].concat(
      chunk(data.slice(firstPageSize), contentsPerPage)
    )
    const currentPage = defaultPage

    // sort content
    data.sort(Content.compare)

    return {
      currentPage,
      data,
      pages,
      hasMorePages: pages.length > currentPage,
      // Load More'a tıklanınca sonraki sayfanın gönderileri bu özelliğin içerisine eklenecek
      // çok nadir olarak geçersiz bir sayfa görüntülenmek istenebilir.
      // geçersizden kasıt kullanıcının girmiş olduğu içeriklerin toplamda
      // tuttuğu sayfa sayısından daha büyük bir sayfa numarasıdır.
      visibleContents: pages[defaultPage - 1] || []
    }
  })
  const onLoadMoreClick = useCallback(() => {
    const nCurrentPage = state.currentPage + 1
    const page = state.pages[nCurrentPage - 1]

    if (!page) {
      return
    }

    setState({
      ...state,
      currentPage: nCurrentPage,
      visibleContents: state.visibleContents.concat(page),
      hasMorePages: state.pages.length > nCurrentPage
    })
  }, [state, setState])

  const links = usePrevNextLinks({
    page: state.currentPage,
    data: state.data,
    pageSize: contentsPerPage,
    slug
  })

  return { ...state, onLoadMoreClick, links }
}
