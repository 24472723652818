import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import User from '../../models/User'

import './index.css'

import LoadMoreButton from '@c/common/LoadMoreButton'
import ShareBox from '@c/share-box'
import AuthorResourceCard from '@c/author-resource-card'
import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'
import usePaginatedContentList from '@h/use-paginated-content-list'
import makeSlug from '@u/make-slug'
import withLocation from '@/withLocation'
import ProjectImage from '@/components/project-image'

const CONTENTS_FIRST_PAGE = 3
const CONTENTS_PER_PAGE = 10

function ContentBlock({ content, locale, pageSettings }) {
  const { resourcesSlug } = pageSettings
  const contentTypeSlug = content.constructor.getContentTypeSlug(pageSettings)

  return (
    <AuthorResourceCard
      url={makeSlug.generic(
        locale,
        resourcesSlug,
        contentTypeSlug,
        content.getSlug()
      )}
      type={content.constructor.getTitlePrefix(pageSettings)}
      title={content.getTitle()}
      action={content.constructor.getAction(pageSettings)}
    >
      <div dangerouslySetInnerHTML={{ __html: content.getDescription() }} />
    </AuthorResourceCard>
  )
}

const Page = ({ data, pageContext, search }) => {
  const { user, pageSettings, teamPage } = data
  const { books, posts, videos } = data
  const contents = [books, posts, videos]
    .map(c => c.edges)
    .flatMap(edges => edges.map(e => e.node))
  const {
    hasMorePages,
    links,
    onLoadMoreClick,
    visibleContents
  } = usePaginatedContentList({
    contents,
    contentsFirstPage: CONTENTS_FIRST_PAGE,
    contentsPerPage: CONTENTS_PER_PAGE,
    search,
    slug: makeSlug.generic(pageContext.locale, teamPage.slug, user.nickname)
  })
  const model = new User(user)

  const parentPages = [
    {
      title: teamPage.title,
      url: makeSlug.generic(pageContext.locale, teamPage.slug)
    }
  ]
  const name = `${user.firstName} ${user.lastName}`
  const title = `${name} | ${pageSettings.profileTitle}`
  return (
    <Layout
      locale={user.locale}
      seo={{
        ...model.getSeoComponentProps(teamPage._allSlugLocales, {
          title,
          link: links
        })
      }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-40">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={parentPages} currentPage={name} />
          </div>
        </div>
      </div>

      {/* BODY */}
      <div className="container">
        {/* PROFILE */}
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2 ta-center">
            <header className="author-bio">
              {user.profileImage && (
                <ProjectImage
                  style={{ margin: '0 auto' }}
                  image={user.profileImage}
                />
              )}
              <h1 className="h2 mt-16">{name}</h1>
              <p className="mt-8">{user.jobPosition}</p>
              <ShareBox
                className="mt-16"
                linkedinLink={user.linkedinLink}
                twitterLink={user.twitterLink}
              />
            </header>
            <div
              className="author-info mt-32"
              dangerouslySetInnerHTML={{ __html: user.shortDescription }}
            />
          </div>
        </div>

        {/* RESOURCES */}
        {!!visibleContents?.length && (
          <div className="row mt-80">
            <div className="col-12 col-lg-8 offset-lg-2">
              <div className="ta-center">
                <PageHeader
                  type="h3"
                  title={pageSettings.userProfileResourceListTitle}
                />
              </div>

              {visibleContents.map(c => (
                <ContentBlock
                  key={c.id}
                  content={c}
                  locale={user.locale}
                  pageSettings={pageSettings}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      {/* LOAD MORE */}
      <div className="ta-center mt-40">
        {hasMorePages && (
          <LoadMoreButton
            disabled={!hasMorePages}
            href={get(
              links.find(link => link.rel === 'next'),
              'href'
            )}
            locale={pageContext.locale}
            onClick={onLoadMoreClick}
          />
        )}
      </div>
    </Layout>
  )
}

export default withLocation(Page)

export const query = graphql`
  query AuthorProfilePageQuery($id: String, $locale: String) {
    user: datoCmsUser(id: { eq: $id }) {
      _allNicknameLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      firstName
      lastName
      nickname
      jobPosition
      shortDescription
      linkedinLink
      twitterLink
      profileImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 232)
            }
          }
        }
      }
    }

    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      profileTitle
      userProfileResourceListTitle

      resourcesSlug

      resourcesArticleSingular
      resourcesArticleSlug
      resourcesArticleAction

      resourcesBookSingular
      resourcesBookSlug
      resourcesBookAction

      resourcesVideoSingular
      resourcesVideoSlug
      resourcesVideoAction
    }

    teamPage: datoCmsTeamPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      locale
      slug
      title
    }

    # ARTICLE
    posts: allDatoCmsArticle(
      filter: {
        locale: { eq: $locale }
        slug: { regex: "/.+/" }
        author: { id: { eq: $id } }
      }
      sort: { fields: [publishDate, meta___createdAt], order: DESC }
    ) {
      edges {
        node {
          ...ArticleItem
        }
      }
    }

    # BOOK
    books: allDatoCmsBook(
      filter: {
        locale: { eq: $locale }
        slug: { regex: "/.+/" }
        author: { id: { eq: $id } }
      }
      sort: { fields: [meta___createdAt], order: DESC }
    ) {
      edges {
        node {
          ...BookItem
        }
      }
    }

    # VIDEO
    videos: allDatoCmsVideo(
      filter: {
        locale: { eq: $locale }
        slug: { regex: "/.+/" }
        author: { id: { eq: $id } }
      }
      sort: { fields: [createdAtCustom], order: DESC }
    ) {
      edges {
        node {
          ...VideoItem
        }
      }
    }
  }
`
